import React, { useEffect, useState } from 'react';
import {
  Broker,
  ButtonsContainer,
  CloseIcon,
  CustomButton,
  CustomModal,
  HeadContainer,
  MainContainer,
  OfferInfo,
  BrokerBio,
  RedText,
  CheckBoxLabel,
  CustomCheckBox,
  ServicesContainer,
  DoneAllIcon,
  ServiceCard,
  AvailableContainer,
  AvailableServicesText,
  AdditionalText,
  BrokerImage,
  BrokerInfo,
  BrokerNotFoundImage,
} from './styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import ProfileService from '../../../../services/profile.service';

const ModalAcceptOffer = ({ offer, closeFun, submit }) => {
  const [avatar, setAvatar] = useState(null);
  const [acceptRules, setAcceptRules] = useState(false);
  const { broker, commission, terms, flat_fee } = offer || {};
  const services = broker?.profile?.services?.provides?.map(({ name }) => name) || [];

  useEffect(() => {
    if (avatar) setAvatar(null);

    if (broker?.id) {
      ProfileService.getAvatarById(broker.id).then(({ data }) => setAvatar(data));
    }
  }, [offer]);

  const readerImage = () => {
    return URL.createObjectURL(avatar);
  };

  return (
    <CustomModal
      open={Boolean(offer)}
      onClose={closeFun}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <MainContainer>
        <HeadContainer>
          {broker && (
            <BrokerInfo>
              {avatar ? <BrokerImage src={readerImage()} /> : <BrokerNotFoundImage />}
              <Broker>Broker: {`${broker.first_name} ${broker.last_name[0]}***`}</Broker>
            </BrokerInfo>
          )}
          <div onClick={closeFun}>
            <CloseIcon />
          </div>
        </HeadContainer>
        <OfferInfo>
          <span>
            Commission:
            <RedText>{flat_fee ? ` ${flat_fee} $` : ` ${commission} %`}</RedText>
          </span>
          <span>
            Terms: <RedText>{terms} Months</RedText>
          </span>
          <span>City: {broker?.profile?.company?.city}</span>
          <span>State: {broker?.profile?.company?.state}</span>
        </OfferInfo>
        <BrokerBio>{broker?.profile?.about?.bio}</BrokerBio>
        <AvailableContainer>
          <AvailableServicesText>Available Services</AvailableServicesText>
          <AdditionalText> **Additional fees may apply</AdditionalText>
        </AvailableContainer>
        {Boolean(services.length) && (
          <ServicesContainer>
            {services.map(service => (
              <ServiceCard key={service}>
                <DoneAllIcon />
                {service}
              </ServiceCard>
            ))}
          </ServicesContainer>
        )}
        <FormControlLabel
          control={<CustomCheckBox onChange={() => setAcceptRules(!acceptRules)} checked={acceptRules} />}
          label={
            <CheckBoxLabel>
              {'If you click the "Accept" button, you agree to list your property with this broker and to our '}
              <a style={{ color: '#131217' }} href={'https://fetchabroker.com/rules/'}>
                rules.
              </a>
            </CheckBoxLabel>
          }
        />
        <ButtonsContainer>
          <CustomButton onClick={closeFun} variant="outlined">
            Reject
          </CustomButton>
          <CustomButton disabled={!acceptRules} onClick={submit} variant="contained" color="error">
            Accept Offer
          </CustomButton>
        </ButtonsContainer>
      </MainContainer>
    </CustomModal>
  );
};

export default ModalAcceptOffer;
