import { createGlobalStyle } from 'styled-components';
import { fontFamily } from '../utils/constants';
import fontFileMedium from '../fonts/PublicSans-Medium.ttf';
import fontFileLight from '../fonts/PublicSans-Light.ttf';

const GlobalStyle = createGlobalStyle`
    @font-face {
    font-family: 'Public Sans';
    font-weight: 500;
    src: url(${fontFileMedium});
    }

    @font-face {
      font-family: 'Public Sans';
      font-weight: 300;
      src: url(${fontFileLight});
    }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: ${fontFamily} !important;
  };
  
  html {
    scroll-behavior: smooth;
  }

  body > #root {
    //height: 100vh;
  }
`;

export default GlobalStyle;
