import styled from 'styled-components';
import { Box, Button, Modal } from '@mui/material';
import { ReactComponent as Close } from '../../../../icons/close.svg';
import { ReactComponent as DoneAll } from '../../../../icons/done_all.svg';
import Checkbox from '@mui/material/Checkbox';

export const MainContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 764px;
  width: calc(100% - 40px);
  background-color: white;
  border-radius: 12px;
  padding: 32px;
`;

export const CustomModal = styled(Modal)`
  background: rgba(30, 34, 42, 0.32);
  backdrop-filter: blur(2px);
`;

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const RedText = styled.span`
  color: #d32f2f;
`;

export const OfferInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 64px;
`;

export const BrokerBio = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.15px;
  margin-top: 30px;
  margin-bottom: 26px;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6px 24px;
  margin-top: 18px;
`;

export const CustomButton = styled(Button)`
  flex-grow: 1;
  &.MuiButton-root {
    min-width: 200px;
    padding: 9px 32px;
    border-radius: 12px;
    font-size: 20px;
    text-transform: capitalize;
    &:nth-child(1) {
      color: #2c2c2e;
      border: 1px solid #2c2c2e;
    }
  }
`;

export const BrokerInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

export const Broker = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.4px;
  letter-spacing: 0.15px;
`;

export const BrokerImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 120px;
`;

export const BrokerNotFoundImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 120px;
  border: 1px solid grey;
`;

export const CustomCheckBox = styled(Checkbox)`
  &.Mui-checked {
    color: ${props => props.theme.palette.redMain} !important;
  }
`;

export const CheckBoxLabel = styled.span`
  width: 100%;
`;

export const ServicesContainer = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const DoneAllIcon = styled(DoneAll)``;

export const ServiceCard = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 6px;
`;

export const AvailableContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile_small}) {
    flex-direction: inherit;
  }
`;
export const AvailableServicesText = styled.span`
  font-weight: bold;
`;
export const AdditionalText = styled.span`
  font-weight: 100;
  font-size: 10px;
  margin-left: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: 6px;
  }
`;
