import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import {
  LinkContainer,
  CustomPagination,
  MainContainer,
  PaginationContainer,
  RedButton,
  KeyboardBackSpaceIcon,
  OffersContainer,
  HeadContainer,
  CircleContainer,
} from './styles';
import OffersTable from '../../components/property-offers/table/table';
import { getPropertyById } from '../../slices/property';
import ModalAcceptOffer from '../../components/property-offers/modal/accept-offer';
import { acceptOffer as acceptOfferApi, createOffer as createOfferApi } from '../../slices/offer';
import PreviewOffer from './preview-offer';
import ModalSubmitOffer from '../../components/property-offers/modal/submit-offer';
import OfferCard from '../../components/offerCard';

const COUNT_PAGE = 20;

const OffersProperty = () => {
  const { user } = useSelector(state => state.auth);
  const { property, loading } = useSelector(state => state.properties);

  const { propertyId } = useParams();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [chooseOffer, setChooseOffer] = useState(null);
  const [open, setOpen] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isBroker, setIsBroker] = useState(false);

  useEffect(() => {
    dispatch(getPropertyById(propertyId));
  }, [propertyId]);

  useEffect(() => {
    setIsBroker(user?.role?.code === 'broker');
  }, [user]);

  const offersProperty = useMemo(() => {
    if (!property?.offers) return [];
    setIsOwner(property?.owner?.id === user?.id);
    return property.offers;
  }, [property]);

  const offers = useMemo(() => {
    return offersProperty.slice((page - 1) * COUNT_PAGE, page * COUNT_PAGE);
  }, [offersProperty, page]);

  const yourOffer = useMemo(() => {
    return offersProperty.find(offer => offer.broker.id === user?.id);
  }, [offersProperty]);

  const acceptOffer = useMemo(() => {
    return offersProperty.find(offer => offer.accepted_at);
  }, [offersProperty]);

  const acceptOfferById = () => {
    dispatch(acceptOfferApi(chooseOffer.id));
    setChooseOffer(null);
  };

  const createOffer = data => {
    const offer = {
      header: '-',
      body: '-',
      commission: data.typeCommission === 'commission_rate' ? data.commission : null,
      flat_fee: data.typeCommission !== 'commission_rate' ? data.commission : null,
      terms: data.terms,
      property: property.id,
      broker: user.email,
    };
    dispatch(createOfferApi(offer));
    setOpen(false);
  };

  if (loading || !property) {
    return (
      <CircleContainer>
        <CircularProgress style={{ color: '#C12026', marginTop: 300 }} />
      </CircleContainer>
    );
  }

  return (
    <MainContainer>
      <HeadContainer>
        <LinkContainer to={'/'}>
          <KeyboardBackSpaceIcon />
          {property?.address}
        </LinkContainer>
        {isBroker && property && !acceptOffer && !yourOffer && (
          <div style={{ minWidth: '189px' }}>
            <RedButton onClick={() => setOpen(true)} variant="contained" color="error">
              Submit offer
            </RedButton>
          </div>
        )}
      </HeadContainer>

      {property && <PreviewOffer offer={acceptOffer || yourOffer} property={property} isOwner={isOwner} />}

      {!acceptOffer && isOwner && (
        <>
          <OffersTable rows={offers} setChooseOffer={setChooseOffer} />

          <OffersContainer>
            {offers.map(offer => (
              <OfferCard key={offer.id} offer={offer} setChooseOffer={setChooseOffer} />
            ))}
          </OffersContainer>

          <PaginationContainer>
            <CustomPagination
              page={page}
              onChange={(e, value) => setPage(value)}
              variant="outlined"
              shape="rounded"
              count={Math.ceil(offersProperty.length / COUNT_PAGE)}
              showFirstButton
              showLastButton
            />
          </PaginationContainer>
        </>
      )}

      <ModalAcceptOffer offer={chooseOffer} closeFun={() => setChooseOffer(null)} submit={acceptOfferById} />
      <ModalSubmitOffer open={open} closeFun={() => setOpen(false)} submit={createOffer} />
    </MainContainer>
  );
};

export default OffersProperty;
