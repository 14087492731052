import React from 'react';
import { Paper, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { BodyTableCell, CustomTableRow, HeaderTableCell, RedButton, RedText, TableContainer } from './styles';
import BrokerInfo from './broker-info';
import { format } from 'date-fns';

const OffersTable = ({ rows, setChooseOffer }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {['Broker', 'Date', 'Location', 'Commission', 'Terms', 'Listing'].map(cell => (
              <HeaderTableCell key={cell}>{cell}</HeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <CustomTableRow key={row.id}>
              <BodyTableCell>
                <BrokerInfo broker={row.broker} />
              </BodyTableCell>
              <BodyTableCell>{format(new Date(row.created_at), 'MM.dd.yyyy HH:mm')}</BodyTableCell>
              <BodyTableCell>
                {row.broker.profile?.company?.city}, {row.broker.profile?.company?.state}
              </BodyTableCell>
              <BodyTableCell>
                <RedText style={{ fontSize: '26px' }}>
                  {row.flat_fee ? `${row.flat_fee} $` : `${row.commission} %`}
                </RedText>
              </BodyTableCell>
              <BodyTableCell>
                <RedText style={{ fontSize: '26px' }}>{row.terms} Months</RedText>
              </BodyTableCell>
              <BodyTableCell>
                <RedButton onClick={() => setChooseOffer(row)} variant="contained" color="error">
                  View Details
                </RedButton>
              </BodyTableCell>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OffersTable;
