import React from 'react';
import { NumericFormat } from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  ButtonsContainer,
  CloseIcon,
  CustomButton,
  CustomModal,
  HeadContainer,
  HeadTitle,
  MainContainer,
  CheckBoxLabel,
  CustomCheckBox,
  InputsContainer,
  CustomTextField,
  ContainerFirstInput,
  FormSelectContainer,
} from './styles';
import { joiResolver } from '@hookform/resolvers/joi';
import { schemaSubmitOffer } from '../../../../joi';
import { InputLabel } from '@mui/material';

const ModalSubmitOffer = ({ open, closeFun, submit }) => {
  const {
    watch,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      commission: 1,
      typeCommission: 'commission_rate',
      terms: 3,
      acceptRules: false,
    },
    resolver: joiResolver(schemaSubmitOffer),
  });

  const textCheckBoxLabel = () => {
    const rules = (
      <a style={{ color: '#131217' }} href={'https://fetchabroker.com/rules/'}>
        rules
      </a>
    );

    return <>You agree to our {rules} and will be charged a deal fee if this homeowner accepts</>;
  };

  const onChangeSelect = e => {
    setValue('typeCommission', e.target.value);
  };

  return (
    <CustomModal
      open={open}
      onClose={closeFun}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <MainContainer>
        <form onSubmit={handleSubmit(submit)}>
          <HeadContainer>
            <HeadTitle>Submit Your Offer</HeadTitle>
            <div onClick={closeFun}>
              <CloseIcon />
            </div>
          </HeadContainer>
          <InputsContainer>
            <ContainerFirstInput>
              <FormSelectContainer>
                <InputLabel id="select-label">Commission</InputLabel>
                <Select
                  fullWidth
                  id="select"
                  labelId="select-label"
                  defaultValue={'commission_rate'}
                  value={watch('typeCommission')}
                  label="Commission"
                  onChange={onChangeSelect}>
                  <MenuItem value={'flat_fee'}>Flat fee</MenuItem>
                  <MenuItem value={'commission_rate'}>Commission Rate</MenuItem>
                </Select>
              </FormSelectContainer>

              <Controller
                name="commission"
                control={control}
                render={({ field }) =>
                  watch('typeCommission') === 'commission_rate' ? (
                    <NumericFormat
                      customInput={CustomTextField}
                      decimalScale={2}
                      suffix={' %'}
                      decimalSeparator="."
                      fullWidth
                      error={!!errors.commission?.message}
                      helperText={errors.commission?.message?.replace('"commission"', 'Commission Rate')}
                      label="Commission Rate"
                      onValueChange={v => {
                        field.onChange(v.value);
                      }}
                      value={field.value || ''}
                    />
                  ) : (
                    <NumericFormat
                      customInput={CustomTextField}
                      decimalScale={0}
                      prefix="$ "
                      fullWidth
                      error={!!errors.commission?.message}
                      helperText={errors.commission?.message?.replace('"commission"', 'Flat fee')}
                      label="Flat fee"
                      onValueChange={v => {
                        field.onChange(v.value);
                      }}
                      value={field.value || ''}
                    />
                  )
                }
              />
            </ContainerFirstInput>

            <CustomTextField
              select
              fullWidth
              defaultValue={3}
              name={'rate'}
              label="Contract Terms"
              variant="outlined"
              inputProps={register('terms')}
              error={!!errors.terms?.message}
              helperText={errors.terms?.message}>
              {[3, 6, 9, 12].map(el => (
                <MenuItem key={el} value={el}>
                  {el} months
                </MenuItem>
              ))}
            </CustomTextField>
          </InputsContainer>

          <FormControlLabel
            {...register('acceptRules')}
            control={<CustomCheckBox checked={watch('acceptRules')} />}
            label={<CheckBoxLabel>{textCheckBoxLabel()}</CheckBoxLabel>}
          />

          <ButtonsContainer>
            <CustomButton onClick={closeFun} variant="outlined">
              Cancel
            </CustomButton>

            <CustomButton type="submit" disabled={!watch('acceptRules') || !isValid} variant="contained" color="error">
              Submit Offer
            </CustomButton>
          </ButtonsContainer>
        </form>
      </MainContainer>
    </CustomModal>
  );
};

export default ModalSubmitOffer;
