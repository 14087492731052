import { OfferContainer, OfferInfo, RedButton, RedText } from './styles';
import React from 'react';
import { format } from 'date-fns';

const OfferCard = ({ offer, setChooseOffer }) => {
  const { broker, commission, terms, created_at, flat_fee } = offer;
  return (
    <OfferContainer>
      <div>Broker: {`${broker.first_name} ${broker.last_name[0]}***`}</div>
      <OfferInfo>
        <span>Date: {format(new Date(created_at), 'MM.dd.yyyy HH:mm')}</span>
        <span>
          Location: {broker.profile?.company?.city}, {broker.profile?.company?.state}
        </span>
        <span>
          Commission:
          <RedText>{flat_fee ? ` ${flat_fee} $` : ` ${commission} %`}</RedText>
        </span>
        <span>
          Terms: <RedText>{terms} Months</RedText>
        </span>
      </OfferInfo>
      <RedButton onClick={() => setChooseOffer(offer)} variant="contained" color="error">
        View Details
      </RedButton>
    </OfferContainer>
  );
};

export default OfferCard;
